<template>
  <Main center>
    <Status :heading="getLocal(language, 'declined.header')" name>
      <template v-slot:picture>
        <svg width="85" height="105" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M84.505 2.523a4.186 4.186 0 00-3.834-2.56H4.148c-1.1 0-2.155.436-2.932 1.213A4.138 4.138 0 000 4.103v96.624a4.14 4.14 0 001.216 2.927 4.157 4.157 0 002.932 1.213h76.526a4.16 4.16 0 002.932-1.214 4.145 4.145 0 001.216-2.926V4.103a4.06 4.06 0 00-.314-1.58h-.003zm-.607 98.204a3.221 3.221 0 01-.946 2.276 3.233 3.233 0 01-2.28.944H4.147a3.23 3.23 0 01-2.281-.943 3.219 3.219 0 01-.946-2.277V4.103a3.222 3.222 0 01.947-2.276 3.235 3.235 0 012.28-.945h76.526a3.247 3.247 0 012.995 2.026 3.185 3.185 0 01.232 1.195l-.003 96.624z"
            fill="#3F3D56"
          />
          <path
            d="M68.535 22.775h-23.05a1.846 1.846 0 01-1.843-1.84 1.839 1.839 0 011.844-1.841h23.05a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.535 32.437h-23.05a1.847 1.847 0 01-1.706-1.135 1.838 1.838 0 011.706-2.546h23.05a1.849 1.849 0 011.706 1.136 1.838 1.838 0 01-1 2.406c-.224.092-.464.14-.706.14z"
            fill="#3F3D56"
          />
          <path
            d="M33.66 37.94H16.815a2.08 2.08 0 01-2.075-2.071v-19.84a2.071 2.071 0 012.075-2.071H33.66a2.079 2.079 0 012.074 2.07v19.84a2.079 2.079 0 01-2.075 2.07z"
            fill="#F2323F"
          />
          <path
            d="M68.376 52.223H16.283a1.846 1.846 0 01-1.844-1.841 1.838 1.838 0 011.845-1.84h52.092a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.376 61.885H16.283a1.846 1.846 0 01-1.844-1.84 1.838 1.838 0 011.845-1.84h52.092a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.376 71.547H16.282a1.846 1.846 0 01-1.842-1.84 1.839 1.839 0 011.842-1.84h52.093a1.849 1.849 0 011.706 1.135 1.838 1.838 0 01-1.706 2.545zM68.376 81.21H16.283a1.846 1.846 0 01-1.844-1.84 1.838 1.838 0 011.845-1.841h52.092a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.84zM68.376 90.872H16.283a1.846 1.846 0 01-1.844-1.84 1.838 1.838 0 011.845-1.841h52.092a1.846 1.846 0 011.844 1.84 1.839 1.839 0 01-1.844 1.841z"
            fill="#CCC"
          />
        </svg>
      </template>

      <template v-slot:details>
        <P>{{ getLocal(language, 'declined.p1') }}</P>
      </template>

      <template v-slot:cta>
        <Button fixed expanded large :blank="version === `v3`" href="https://kafene.com/">{{
          getLocal(language, 'home')
        }}</Button>
      </template>
    </Status>
  </Main>
</template>

<script>
import { useStore } from 'vuex'
import { getLocal } from '../data/locales'

import Main from '../components/Main.vue'
import Status from '../components/Status.vue'
import Button from '../components/common/Button'
import P from '../components/common/P'

export default {
  name: 'Declined',
  setup() {
    return {
      store: useStore()
    }
  },
  components: {
    Button,
    Main,
    Status,
    P
  },
  computed: {
    version() {
      return this.store.getters['application/getVersion']
    },
    language() {
      return this.$store.getters.getLanguage
    }
  },
  methods: { getLocal }
}
</script>
