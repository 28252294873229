<template>
  <div class="status" :class="{ 'status--wide': wide }">
    <div class="status__picture">
      <slot name="picture" />
    </div>

    <div class="status__content">
      <H h2>
        {{ heading }} <span v-if="name"><br />{{ fullName }}!</span>
      </H>

      <div class="status__details">
        <slot name="details" />
      </div>

      <P v-if="amount" class="status__amount">{{ amount }}</P>

      <div class="status__details">
        <slot name="additionalDetails" />
      </div>
    </div>

    <div class="status__cta">
      <slot name="cta" />
    </div>
  </div>
</template>

<script>
import { useStore } from 'vuex'

import H from './common/H'
import P from './common/P'

export default {
  setup() {
    return {
      store: useStore()
    }
  },
  components: {
    H,
    P
  },
  props: {
    heading: String,
    name: Boolean,
    paragraph: String,
    amount: String,
    wide: Boolean
  },
  computed: {
    fullName() {
      return `${this.store.getters['application/getFirstName']} ${this.store.getters['application/getLastName']}`
    }
  }
}
</script>

<style lang="scss">
.status {
  margin: 0 auto;
  margin-top: 40px;

  @include media-query(sm) {
    margin-top: 96px;
    max-width: 320px;
  }

  &__picture {
    > img,
    svg {
      margin: 0 auto;
    }
  }

  &__content {
    margin-top: 32px;

    > * + * {
      margin-top: 20px;
    }
  }

  &__details {
    > * + * {
      margin-top: 1em;
    }
  }

  &__amount {
    margin-top: 12px;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #4e5d78;
  }

  &__cta {
    margin-top: 64px;

    > * + * {
      margin-left: 20px;
    }
  }

  &--wide {
    @include media-query(sm) {
      max-width: 400px;
    }
  }
}
</style>
