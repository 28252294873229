<template>
  <Main center>
    <Status heading="Are you sure you want to quit?" wide>
      <template v-slot:picture>
        <svg width="120" height="105" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m64.432 43.455-.887 32.59h-8.318l-.909-32.59h10.114ZM59.386 90.59c-1.5 0-2.788-.53-3.863-1.591-1.076-1.076-1.606-2.364-1.591-3.864-.015-1.484.515-2.757 1.59-3.818 1.077-1.06 2.364-1.59 3.864-1.59 1.44 0 2.705.53 3.796 1.59 1.09 1.06 1.644 2.334 1.659 3.818-.015 1-.28 1.917-.795 2.75a5.877 5.877 0 0 1-1.978 1.978 5.165 5.165 0 0 1-2.682.727Z"
            fill="#F2323F"
          />
          <path
            d="M53.505 4.25c2.887-5 10.103-5 12.99 0l51.096 88.5c2.886 5-.722 11.25-6.496 11.25H8.905c-5.774 0-9.382-6.25-6.496-11.25l51.096-88.5Z"
            stroke="#3F3D56"
          />
        </svg>
      </template>

      <template v-slot:details>
        <P>All your current progress will be lost</P>
      </template>

      <template v-slot:cta>
        <div class="close-footer">
          <div class="close-footer__item">
            <Button expanded large light @click="exit">Yes, exit</Button>
          </div>

          <div class="close-footer__item">
            <Button expanded large @click="stay">No, stay here</Button>
          </div>
        </div>
      </template>
    </Status>
  </Main>
</template>

<script>
import { useStore } from 'vuex'

import Main from '../components/Main.vue'
import Status from '../components/Status.vue'
import Button from '../components/common/Button'
import P from '../components/common/P'

export default {
  components: {
    Button,
    Main,
    Status,
    P
  },
  setup() {
    return {
      store: useStore()
    }
  },
  methods: {
    stay() {
      this.$router.go(-1)
    },
    exit() {
      this.store.dispatch('logout')
      this.$iframeBus.post('close-modal')
    }
  }
}
</script>

<style lang="scss" scoped>
.close-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 -10px;

  &__item {
    flex: 0 0 50%;
    padding: 0 10px;

    .button {
      padding: 18px 24px;

      @include media-query(sm) {
        padding: 18px 40px;
      }
    }
  }
}
</style>
